#examslist{
    
    transition: 0.3s;
    tr:not([expanded="true"]){
        cursor: pointer;    
        &:hover{
            box-shadow: 0 0 10px var(--shadow);
        }
    }

}

.download{
    --textcolor: var(--link);
    cursor: pointer;
    &:hover{
       text-decoration: underline;
    }
}

#available_filter button{
    cursor: pointer;
}

#examslistcontainer button{
    cursor: pointer;
}

#filteroptions{
    padding-top: 1rem;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1rem 1fr;
}

#scroll{
    max-width: 100%;
    overflow: scroll;
}

#fuzzysearch{
    width: 100%;
    padding: 0.5rem;
    font-size: medium;
}

#examslisttable{

    font-size: .8em;
    min-width: 100%;

    tr[visible=false]{
        display:none;
    }
    tr{
        --backgroundcolor: none;
    }
    td, th{
        word-break: keep-all;
    }

    tr>td:nth-child(1){
            min-width: 13rem;
            width: 40%;
    }
    tr>td:nth-child(2){
            width: 15%;
    }
    tr>td:nth-child(3){
            width: 35%;
    }
    tr>td:nth-child(4){
            width: 25%;
    }
    .name{
        font-weight: bold;
    }
}

/* The Modal (background) */
#errormodal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    h3{
        margin-top: 0 !important;
      }
  }

  /* Modal Content/Box */
  .errormodal-content {
    max-width: 70ch;
    --backgroundcolor: var(--forground);
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }
  
  /* The Close Button */
  .close {
    --textcolor: var(--tertary);
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    --textcolor: var(--primary);
    text-decoration: none;
    cursor: pointer;
  }

