.burgermenu{
    padding: 0rem 1.5rem 0rem 1rem;
}
#article, #aside {
    padding: 0rem 1rem 0rem 3rem;
}

#header #cards {
    padding-left: 0;
    padding-right: 0;
}

.container {
    display: grid;
    grid-template-areas: "sidebar main";
    margin: 0 auto;
    max-width: $max-width;
    &.reset{
        grid-template-columns: none;
    }
    &.topnav{
        grid-template-areas: "logo breadcrumb social";
        grid-template-columns: 15rem 3fr 12rem;
    }
    &.cards{
        grid-template-areas: "card1 card2 card3";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
    }
}

.container_inner{
    display: grid;
    grid-template-areas: 
    "intro aside"
    "content aside";
    grid-template-columns: 1fr 11rem;
    grid-template-rows: auto 1fr;
}

.sticky{
    position:sticky;
    top:calc(2 * #{$topnav-height} + 2rem);
    height: min-content;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#tableofcontents{
    grid-area: aside;
    overflow: visible;
}

.intro{
    grid-area: intro;
    margin-right: 2rem;
}

.content{
    grid-area: content;
    margin-right: 2rem;
}

//top navigation
.topbar{
    max-height: $topnav-height;
    transition: max-height 0.2s;
}
.topnav{
    display: grid;
    grid-template-columns: 1fr auto;
}

//container for topnavigation
.topbar{
    position: sticky;
    top: 0;
    z-index: 9;
}

//burger & language menu
.burgermenu{
    position: sticky;
    top: $topnav-height;
    z-index: 10;
    pointer-events: all;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 4px var(--shadow);
    >label{
        height: 2.5rem;
    }
}


//side navigation
#sidenav{
    position: fixed;
    top: $topnav-height;
    left: -100%;
    transition: left 0.3s, position 0.3s;
    overflow: auto;
    height: calc(100vh - #{$topnav-height});
    width: 100vw;
    z-index: 8;
    overscroll-behavior: contain;
}

//article
#article{
    margin-top: 1rem;
    margin-bottom: 3rem;
}

//only display on mobile
.dm{
    display: flex;
}

.container_cards{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.w50, .w30, .w70{
    width: 100%
}

.aside{
    grid-area: aside;
    margin-top: 1rem;
    font-size: 0.9em;
}

.socials{
    grid-area: social;
}

.socials>small{
    display: none !important;
}

#breadandlang{
    display: none;
}