//hide elements
#header, #content, #breadandlang{
    display: none;
}

#article, #aside, .burgermenu{
    padding: 0rem 1rem 0rem 1rem;
}

.container_inner{
    display: grid;
    grid-template-areas: 
    "intro"
    "aside"
    "content";
    grid-template-rows: auto auto 1fr;
}

.content{
    overflow: hidden;
}

//top navigation
.topbar{
    max-height: $topnav-height;
    transition: max-height 0.2s;
}
.topnav{
    display: grid;
    grid-template-columns: 1fr auto;
}

//burger & language menu
.burgermenu{
    position: sticky;
    top: 0;
    z-index: 10;
    pointer-events: all;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 4px var(--shadow);
    >label{
        height: 2.5rem;
    }
}

//main container
main{
    display: flex;
    flex-direction: column;
    max-height: min-content;
    overflow: hidden;
}

//side navigation
#sidenav{
    position: fixed;
    top: $topnav-height;
    left: -100%;
    transition: left 0.3s, position 0.3s;
    overflow: auto;
    height: calc(100vh - #{$topnav-height});
    width: 100vw;
    z-index: 8;
    overscroll-behavior: contain;
}

//only display on mobile
.dm{
    display: flex;
}

.container_cards{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.w50, .w30, .w70{
    width: 100%
}

.socials>small{
    display: none !important;
}