#header {
    height: fit-content;
    --backgroundcolor: rgb(49, 52, 62);
    background-position: center bottom;
    background-blend-mode: overlay;
    background-size: cover;

    #cards {
        padding-left: 5rem;
        padding-right: 5rem;
        >.card {
            margin: 1.5rem;
            padding: 1rem;
            height: min-content;
            display: inline-block;
            --textcolor: var(--primary);
            text-decoration: none;
        
            //border
            border: 3px solid var(--secondary);
            --backgroundcolor: var(--background);
            box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--secondary);
            transition: transform 0.2s ease-in-out, box-shadow 0.5s ease-in-out;

            //for smooth scaling on hover
            -webkit-font-smoothing: subpixel-antialiased;
            backface-visibility: hidden;
            transform: translateZ(0);
            
            h2 {
                --textcolor: hsl(var(--color), 40%, 40%);
                margin: 0;
                font-size: 1rem;
                letter-spacing: 0.15rem;
                text-transform: uppercase;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }

            hr{
                border-top: 1px solid var(--primary);
                margin: .5em 0 .5em 0;
            }
        
            >h2:first-child {
                margin: 0;
            }
        
            &:hover {
                transform: scale(1.05);
                box-shadow: 10px -10px 0 -3px var(--background), 10px -10px hsl(var(--color), 50%, 30%);
            }
        }
    }
}

