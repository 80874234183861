#locale_de:checked +* +*
{
    *[lang="en"]
    {
        display: none;
    }
    +* *[lang="en"]
    {
        display: none;
    }
    +* +* *[lang="en"]
    {
        display: none;
    }
	+* +* +* *[lang="en"]
    {
        display: none;
    }
	+* +* +* +* *[lang="en"]
    {
        display: none;
    }
    +* +* +* +* +* *[lang="en"]
    {
        display: none;
    }
    +* .languageMenu [lang="en"]
    {
        opacity: 0.35;
    }
}
#locale_en:checked +*
{
    *[lang="de"]
    {
        display: none;
    }
    +* *[lang="de"]
    {
        display: none;
    }
    +* +* *[lang="de"]
    {
        display: none;
    }
    +* +* +* *[lang="de"]
    {
        display: none;
    }
    +* +* +* +* *[lang="de"]
    {
        display: none;
    }
    +* +* +* +* +* *[lang="de"]
    {
        display: none;
    }
    +* .languageMenu [lang="de"]
    {
        opacity: 0.35;
    }
}

