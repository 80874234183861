#article {
    >*{
        padding: $padding-content;
    }

    transition: opacity;
    transition-duration: 1s;
    transition-timing-function: ease-out;

    // no margin on first article item

    padding-top: .5rem;
    padding-bottom: .5rem;
    pointer-events: all;
    word-wrap: break-word;
    word-break: break-word;

    h1{
        font-size: 2rem;
    }
    h2{
        font-size: 1.7rem;
    }
    
    h1, h2, h3, h4, h5, h6, p {
        scroll-margin-top: $topnav-height;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
        --textcolor: var(--primary);
    }

    *:not(h1, h2, h3, h4, h5, h6){
        &+h1, &+h2, &+h3, &+h4, &+h5, &+h6, &+h1+h1, &+h2+h2, &+h3+h3, &+h4+h4, &+h5+h6, &+h6+h6 {
            margin-top: 4rem;
        }
    }

    p{
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    article>h1:first-child{
        margin-top: 0 !important;
    }

    h1 {
        &.display {
            margin-top: .5rem;
            font-size: calc(1.625rem + 2.1vw);
            font-weight: 500;
        }
    }

    .slogan {
        font-size: calc(1.275rem + .3vw);
        font-weight: 400;
        --textcolor: var(--secondary);
        margin-bottom: 1em;
    }

    img{
        padding: 1rem;
    }
    .content{
        //text-align: justify;
        --textcolor: var(--secondary);
        max-width: 70ch;
        p{
            line-height: 1.5rem;
        }
    }
}

.card{
    border-radius: 5px;
    --backgroundcolor: var(--forground);
    box-shadow: 1px 1px 3px var(--shadow);
    padding: 1rem;
    margin-bottom: 2rem;
    h1, h2, h3, h4, h5, h6{
        font-weight: 500 !important;
        margin: 0;
        a{
            --textcolor: var(--header);
        }
    }
}

.profile{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    word-break: keep-all;
    padding: 0;
    overflow: hidden;
    margin: 1rem;
    
    flex-grow: 1;
    min-width: 10rem;
    width: 10rem;
    max-width: 10rem;

    h4{
        margin-top:0 !important;
        margin-bottom: .5rem !important;
    }
    img{
        padding: 0 !important;
    }
    
    .container-1-1{
        margin-bottom: .5rem;
    }

    .links{
        display: flex;
        width: 100%;
        justify-self: end;
        button{
            width: 100%;
            text-align: center;
            padding: .5rem;
            cursor: pointer;
            margin: 0;
        }
    }
}

.profiles{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0 2rem 0;
}

img{
    max-width: 100%;
}

.fsmifood_groups img{
    padding: 0 !important;
}