//logo in topnav
.logo{
    padding-left: 1.1rem !important;
}

//side navigation
#sidenav{
    --backgroundcolor: var(--background);
}

//change to menu (side navigation) view
body>input[type=checkbox]#menutoggler:checked {
    ~.topbar{
        max-height: 0;
    }
    ~.burgermenu{
        top: 0;
    }
    ~div>div>#sidenav{
        left: 0;
    }
}

#menubox{
    padding: 0;
    width: calc(0.8 * 100vw);
    max-width: 30rem;
    margin: 0 auto;
    font-size: 1.2em;
    margin-top: 1rem;
    margin-bottom: 10rem;
    min-height: calc(100vh - 2 * #{$topnav-height});

    >h1{
        --textcolor: var(--primary);
        padding: 0.5rem;
    }

    li{
        width: 100%;
    }

    //general dropout menu style (not visible on default)
    @mixin default{
        font-size: 0;
        opacity: 0;
        transition: font-size 0.2s, opacity 0.2s;
        margin-left: .2em;
        border-left: 3px solid var(--hover);
        --backgroundcolor: var(--background);
    }

    //make dropout menu visible
    @mixin visible{
        font-size: 1em;
        opacity: 1;
    }

    .menuitem{
        //fixed height -> prevent expand on dropout
        height: min-content;
        //border-bottom: 1px solid var(--hover);
        >input+label{ 
            display: flex;
            width: 95%;
            justify-content: space-between;
            margin-left: .2em;
            padding-left: .3em;
        }
        &.more>input[type=checkbox]:checked+label{
            --backgroundcolor: var(--hover);
            &::after{
                content: 'v';
            }
            +ul{
                @include visible();
            }
        }
        
        &.more >input + label + ul{
            @include default();
                      
        }
    }

}

#footer-links{
    flex-direction: column;
    align-items: center;
}

