//grid system
.container {
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: 15rem 1fr;
    gap: 2rem;
    margin: 0 auto;
    max-width: $max-width;
    &.reset{
        grid-template-columns: none;
    }
    &.topnav{
        grid-template-areas: "logo breadcrumb social";
        grid-template-columns: 15rem 3fr 12rem;
    }
    &.cards{
        grid-template-areas: "card1 card2 card3";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
    }
}

.container_inner{
    display: grid;
    grid-template-areas: 
    "intro aside"
    "content aside";
    grid-template-columns: 3fr 12rem;
    grid-template-rows: auto 1fr;
}

#tableofcontents{
    grid-area: aside;
    overflow: visible;
}
.intro{
    grid-area: intro;
    margin-right: 2rem;
}
.content{
    grid-area: content;
    margin-right: 2rem;
}


//container for topnavigation
.topbar{
    position: sticky;
    top: 0;
    z-index: 10;
}

//stick content under topnav
.sticky{
    position:sticky;
    top:calc(#{$topnav-height} + 2rem);
    height: min-content;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

//container for the sidenavigation
#sidenav{
    //width needs to be large to get an overflow in the grid system
    // width: 100vw;
    overflow-x:visible;
    z-index: 9;
    //pointer-events are only set on menuitems
    pointer-events: none;

    //blur the article when focus on navigation
    &:hover{
        + #article{
            opacity: 70%;
            *{
                text-shadow: 0 0 4px var(--textcolor) !important;
            }
            *:not(.alert), *:not(button){
                color: rgba(0, 0, 0, 0) !important;
                //background-color: var(--background);
            }
            .alert, .card{
                border-color: rgba(0, 0, 0, 0);
                border-radius: 5px;
            }
            hr, .alert{
                box-shadow: 0 0 4px var(--backgroundcolor);
            }
            .card, button{
                box-shadow: 1px 1px 3px #00000021;
            }

            img{
                filter: blur(3px) grayscale(.5);
                opacity: .8;
            }
            
        }
    }
}

//container for aside
.aside {
    margin-right: 1rem;
    padding-left: 1rem;
    >.alert{
        margin-top: 0 !important;
    }
}

//only for small devices
.dm{
    display: none;
}

.container_cards{
    display: flex;
    flex-wrap: wrap;
    >*{
        margin-right: 1rem;
    }
}

.aside{
    grid-area: aside;
    margin-top: 1rem;
    font-size: 0.9em;
}

.frd{float: right;}
