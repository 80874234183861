.calendar-container{
    gap: 1rem;
    display: grid;
    grid-template-areas: "month" 
    "weekdays"
    "events" 
    "calendar";
    grid-template-columns: 1fr;
    grid-template-rows: 2rem 2rem auto;
}

.aspect-ratio-2{
    display: none;
    padding: 0;
    .month-events{
        position: relative;
    }
}

input[type=radio]:checked {
    ~ .aspect-ratio-2 {
        display: block;
    }
    ~ .aspect-ratio {
        display: none;
    }
}

input[type=radio]#day-uncheck:checked{
    ~ .aspect-ratio-2 {
        display: none !important;
    }
    ~ .aspect-ratio {
        display: block !important;
    }
}

.day-details{
    padding: 0 1rem;
}
.day-date::before{
    content: "x";
    font-size: 3rem;
    position: relative;
    top: -1.4rem;
    font-weight: 100;
    float: right;
}