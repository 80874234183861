
//base colors and layouts
@import 'base';



//big modules
@import 'module_topnav';
@import 'module_header';
@import 'module_sidenav';
@import 'module_article';
@import 'module_footer';

//small (reusable) modules
@import 'module_overview';
@import 'module_alert';
@import 'module_toc';
@import 'module_calendar';
@import 'module_archiv';

//states
@import 'state_colors';
@import 'state_langselect';


// mobile devices
@media screen and (max-width: 900px), (hover: none) {
    @media (min-width: 601px){
        @import 'layout_tablet.scss';
    }
    @media (max-width: 600px){
        @import 'layout_small';
    }
    @import 'state_mobile';
}

// desktop devices
@media screen and (min-width: 901px) and (hover: hover){
    @import 'layout_large';
    @import 'state_desktop';
    @media screen and (max-height: 560px){
        @import 'state_desktop_smallheight.scss';
    }
}

//some calendar breakpoints
@media screen and (max-width: 1160px){
    @import 'state_calendar';
}

// dark mode
@media screen and (prefers-color-scheme: dark) {
    @import 'theme_darkmode';    
}

// print
@media print
{
    @import 'layout_print';
}

