// general
@each $colorname, $color in $colors {
    .#{$colorname} {
       --color: #{$color};
    }
}

// text
@each $colorname, $color in $colors {
    .text-#{$colorname} {
        --textcolor: hsl(#{$color}, 100%, 35%) !important;
    }
}

// background
@each $colorname, $color in $colors {
    .bg-#{$colorname} {
        --backgroundcolor: hsl(#{$color}, 30%, 90%) !important;
    }
}

//table-head-background
@each $colorname, $color in $colors {
    .head-#{$colorname} {
        th{
            --backgroundcolor: hsl(#{$color}, 40%, 30%) !important;
        }
    }
}