#overview {
    display: flex;
    flex-wrap: wrap;
    margin: 4rem 0 2rem 0;

    a {
        display: block;
        margin-right: 2rem;
        width: calc(50% - 2rem);
        text-decoration: none;
        --textcolor: var(--tertary);

        h3 {
            transition: color 0.1s ease-out;
        }

        &:hover {
            h3 {
                --textcolor: var(--highlight-hover);
            }
        }
    }

    p {
        margin-top: 0;
    }

    h3 {
        --textcolor: var(--highlight);
        margin: 0;
    }
}