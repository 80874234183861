//hide elements
#header, #content, #breadandlang > span + span, #breadandlang > span > span:first-child, #breadandlang > span > span:nth-child(2), #sidenav, .burgermenu, footer, .socials, .logo{
    display: none !important;
}

body{
    --theme: white;
}
#breadandlang{
    color: black !important;
}

.container_inner{
    display: grid;
    grid-template-areas: 
    "intro aside"
    "content aside";
    grid-template-columns: 1fr 11rem;
    grid-template-rows: auto 1fr;
}
.intro{
    grid-area: intro;
    margin-right: 2rem;
}

.content{
    grid-area: content;
    overflow: hidden;
    margin-right: 2rem;
}

.aside{
    grid-area: aside;
    margin-top: 1rem;
    font-size: 0.9em;
}