//
//
// classes for aspect ratio's
// 
// 

.aspect-ratio {
    width: 100%;
    padding-top: 80%;
    position: relative;
    margin-bottom: auto;
}
.aspect-ratio-2{
    width: 100%;
    padding-top: 160%;
    position: relative; 
    grid-area: events;
    margin-bottom: auto;
}

.day-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.day, .calendar, .month-events{
    position:absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
}

// grid container for the whole calendar
.calendar-container{
    max-width: 100%;
    gap: 1rem;
    display: grid;
    grid-template-areas: "month weekdays"
    "events calendar";
    grid-template-columns: 1.075fr 2fr;
    grid-template-rows: 2rem 1fr;
    >div{
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.06);
    }
}

// 
// 
// CALENDAR ELEMENTS
// 
//

// month selection
.calendar-month{
    grid-area: month;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    *{
        width: 100%;
        font-size: .9em;
        text-align: center;
        --textcolor: var(--light);
        --backgroundcolor: var(--theme);
        font-weight: 700;
        text-transform: uppercase;
        padding: 5px;
    }
    a{
        width: min-content;
        padding: 5px .5rem;
    }
}

// weekdays header
.calendar-weekdays{
    grid-area: weekdays;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: 2rem;
    >span{
        width: 100%;
        font-size: .9em;
        text-align: center;
        --textcolor: var(--light);
        --backgroundcolor: var(--theme);
        font-weight: 700;
        text-transform: uppercase;
        padding: 5px;
        margin-bottom: 5px;
    }
}

// day/event details
    // container
.month-events{
    height: 100%;
    --backgroundcolor: var(--forground);
}
    // container for one day
.day-details{
    display: none;
    grid-area: details;
    grid-template-areas: "date"
    "events";
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    --textcolor: var(--primary);
    height: calc(100% - 2.3rem);
    padding: 0 0 0 1rem;
}
    // date headline
.day-date{
    padding: 1rem 0;
    font-size: 1.6rem;
    --textcolor: var(--primary);
    font-weight: 400;
    border-bottom: 4px solid var(--cal-bg);
}
    // container for events
.day-events{
    overflow-y: scroll;
    padding: 2rem 0;
}
    // an event
.event{
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--muted);
    padding: 0 .4rem;
    font-size: .9em;
    p{
        margin: 0;
        padding-bottom: 1rem;
    }
    &-title{
        font-weight: 600;
        font-size: .9em;
        padding: 1rem 0;
        --textcolor: var(--header);
        text-transform: uppercase;
    }
    .time{
        --textcolor: var(--muted);
        font-size: .9em;
        line-height: 50%;
    }
}


// calendar days
    // grid-container
.calendar{
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    grid-area: calendar;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-auto-rows: 0px;
    grid-auto-columns: 0px;
}

    // day
.day{
    --backgroundcolor: var(--forground);
    --textcolor: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border: 1px solid var(--cal-bg);
    padding: 10%;

    &.notactive{
        --backgroundcolor: var(--cal-notactive);
        --textcolor: var(--muted);
        box-shadow: none;
    }
    &-nr{
        font-weight: 700;
    }

    &::after{
        --textcolor: var(--highlight);
        float: right;
        width: min-content;
        align-self: flex-end;
        font-size: 4rem;
        line-height: 0;
        top: -1.5rem;
        position: relative;
    }
}



// 
// 
// LOGIC FOR SELECTING DAYS
// 
//

@for $i from 1 to 34 {
    input[type=radio]#day-#{$i}:checked {
        ~ .aspect-ratio-2 > .month-events #day-events-#{$i}{
            display: grid !important;
        }
        ~ .aspect-ratio > .calendar > #ct-day-#{$i} {
            > label{
                --backgroundcolor: var(--cal-checked)!important;
                box-shadow: 0 0 1px 3px var(--cal-bg) inset;
            }
        }
    }

    input[type=radio]#day-#{$i} {
        ~ .aspect-ratio > .calendar > #ct-day-#{$i} {
            > label:hover{
                --backgroundcolor: var(--cal-hover);
            }
            > label::after{
                content:".";
            }
        }
    }
  }

