:root {
    --primary: rgb(209, 209, 209);
    --secondary: #b7b7b7;
    --tertary: #a7b4c0;
    --background: #222;
    --forground: #151515;
    --muted: #8a949e;
    
    //--theme: rgb(38, 63, 91);
    --theme: hsl(var(--theme-deg), 41%, 25%);;
    --header: hsl(var(--theme-deg), 31%, 62%);
    --checked: hsl(var(--theme-deg), 31%, 22%);
    --hover: hsl(var(--theme-deg), 21%, 24%);

    --link: hsl(202, 56%, 56%);

    --cal-hover: hsl(var(--theme-deg), 31%, 17%);
    --cal-checked: hsl(var(--theme-deg), 61%, 15%);
    --cal-notactive: hsl(var(--theme-deg), 5%, 20%);
    --cal-bg: rgba(255, 255, 255, 0.115);
    
    --highlight: hsl(202, 86%, 56%);
    --highlight-hover: hsl(202, 86%, 40%);
}

.alert{
    --backgroundcolor: hsl(var(--color), 25%, 20%);
    --textcolor: hsl(var(--color), 30%, 90%);
    border: 1px solid hsl(var(--color), 20%, 18%);
}

.card {
    --textcolor: var(--secondary);
    h3 a{
        --textcolor: var(--link);
        
    }
}

code span{
    --textcolor: hsl(var(--color), 70%, 50%);
}

button{
    --backgroundcolor: hsl(var(--color), 25%, 20%);
}

// text
@each $colorname, $color in $colors {
    .text-#{$colorname} {
        --textcolor: hsl(#{$color}, 50%, 50%) !important;
    }
}

// background
@each $colorname, $color in $colors {
    .bg-#{$colorname} {
        --backgroundcolor: hsl(#{$color}, 70%, 13%) !important;
    }
}

//table-head-background
@each $colorname, $color in $colors {
    .head-#{$colorname} {
        th{
            --backgroundcolor: hsl(#{$color}, 40%, 20%) !important;
        }
    }
}