$topnav-bg: rgb(38, 63, 91);

$padding-main: .5rem;
$padding-content:.5rem;
$border-radius: 3px;

$max-width: 1320px;
$topnav-height: 3rem;
$menuitem-width: 12rem;

$colors: (
    'green': 118,
    'yellow': 54,
    'orange': 27,
    'pink': 300,
    'red': 0,
    'blue': 205
);

:root {
    --primary: #000000;
    --secondary: #1d1d1d;
    --tertary: #626262;
    --muted: #757575;
    --light: whitesmoke;
    --background: white;
    --forground:  hsl(var(--theme-deg), 99%, 99.9%);
    --blurbackground: var(--forground);
    --blurtext: var(--primary);
    --shadow: #0000007c;
    --shadow-light: #00000030;

    --theme-deg: 212;
    --theme: hsl(var(--theme-deg), 41%, 25%);
    --header: hsl(var(--theme-deg), 41%, 25%);
    --checked: hsl(var(--theme-deg), 41%, 80%);
    --hover: hsl(var(--theme-deg), 20%, 85%);

    --highlight: hsl(202, 83%, 45%);
    --highlight-hover: hsl(202, 83%, 30%);

    --link: hsl(202, 83%, 45%);

    --color: 212;

    --cal-hover: hsl(var(--theme-deg), 31%, 95%);
    --cal-checked: hsl(var(--theme-deg), 41%, 82%);
    --cal-notactive: hsl(var(--theme-deg), 5%, 90%);
    --cal-bg: rgba(0, 0, 0, 0.053);

    --bs-font-sans-serif: "Roboto", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

    // Defaults
    --textcolor: var(--primary);
    --backgroundcolor: var(--theme);
}

//
// basic tags
//

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    line-height: 1.5;
    background: var(--backgroundcolor);
    *{
        // reset Background for all childs
        --backgroundcolor: none;
        color: var(--textcolor);
        background: var(--backgroundcolor);
        box-sizing: border-box;
        -webkit-box-sizing: border-box; 
        -moz-box-sizing: border-box; 
    }
}

main{
    --backgroundcolor: var(--background);
}

// images

img{
    min-width: 1rem;
}

//text

strong{
    font-weight: 700;
}

a{
    text-decoration: none;
    --textcolor: var(--link);
    &:hover{
        text-decoration: underline;
    }
}

hr {
    border: none;
    min-height: 2px;
    margin: 2rem 0 2rem 0;
    --backgroundcolor: var(--hover);
}

button{
    --backgroundcolor: hsl(var(--color), 30%, 80%);
    border: none;
    --textcolor: var(--primary)
}

// blocks

pre {
    display: inline-flex;
    width: 100%;
    margin: 1em 0 1em 0;
    >*{
        width:100% !important;
        padding: .2rem .5rem .2rem .5rem !important;}
}
code{
    width: fit-content;
    padding: 0 .2rem 0 .2rem;
    --backgroundcolor: var(--forground);
    border: 1px solid var(--background);
    box-shadow: 1px 1px 5px var(--shadow);
    overflow-y: scroll;
    span{
        --textcolor: hsl(var(--color), 91%, 20%);
    }
}

blockquote{
    margin: 0;
    padding: 0 1em;
    --textcolor: var(--muted);
    border-left: .25em solid var(--hover);
}

// tables

table{
    caption-side: bottom;
    border-collapse: collapse;
    width: 100%;
    margin: 1em 0 1em 0;
    --textcolor: var(--primary);
    vertical-align: top;
    border-color: #dee2e6;
}

th{
    text-align: start;
    padding: .5rem .5rem;
    --backgroundcolor: var(--theme);
    --textcolor: var(--light);
    border-bottom: solid 1px var(--muted);
}

tbody{
    vertical-align: inherit;
    +tbody, +tfoot{
        border-top: 2px solid var(--muted);
    }
}

tfoot{
    --forground: var(--hover);
}

table:not(.no-background){
    tr{
        &:nth-child(2n-1) {
            --backgroundcolor: var(--forground);
            box-shadow: inset 0 0 5px var(--shadow-light);
        }
    }
}

td{
    padding: .5rem .5rem;
    border-bottom-width: 1px;
}



//
// basic classes
//


// colors

    // backgrounds
.bg-theme{ --backgroundcolor: var(--theme);}
.bg-default{ --backgroundcolor: var(--background);}
.outline { background: none;}
    // text
.muted{ --textcolor: var(--muted)}


// layouts

    // heights
.htn{ height: $topnav-height;}
.h30{ height: 30%}

    //widths
.w100{width: 100%;
padding-left: 0 !important;
padding-right: 0 !important;}
.w70{width: 70%;}
.w50{width: 50%;}
.w30{width: 30%;}
.wfc{
    // wfc := width fit content
    width: 1%;
    white-space: nowrap;
}
.w1r{width: 1rem;}
.wauto{width: auto;}

    // float
.fr{float: right;}
.fl{float: left;}

.nowrap{
    white-space: nowrap;
}

//padding
.p0{
    padding: 0;
}
.p1{
    padding: 1rem;
}
.m0{
    margin: 0 !important;
}
//border
.rounded{
    border-radius: 5px;
}

// Markdown Highlighting
.gt{
    --color: 121;
}

.gh, .gu{
    font-weight: 900;
    --color: 212;
}

.unstyled{
    list-style-type: none;
}

.center{
    margin: 0 auto;
}

// Tables
.t30{
    tr>td:first-child{
            width: 30%;
    }
}

// aspect ratios
.container-1-1 {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
}

.aspect-content{
    position:absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
}
