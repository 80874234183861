

// the sidenavigation
#menubox {

    padding-left: 2.5rem;
    --textcolor: var(--secondary);

    a {
        --textcolor: var(--secondary);
    }

    ul{
        padding-left: 0;
        margin:0;
    }
    
    li {
        list-style-type: none;
        width: min-content;
    }

    // Menuheaders //
    .menuheader {
        pointer-events: all;
        //padding between modules
        margin-bottom: 2rem;
        &:last-child{
            margin-bottom: 0 !important;
        }
        >ul{
            margin-top: .2rem;
        }

        >input+label{
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.9em;
            padding-left: 0.6em;
            a {
                --textcolor: var(--header) !important;
            }
        }

        // border around active menuheader
        &.active {
            >input+label>a>span {
                border: 1px solid var(--theme);
                border-radius: 2px;
                padding-left: .2rem;
                padding-right: .2rem;
            }
        }
    }

    // Menuitems //
    .menuitem{
        label{
            display: flex;
            // space between item title and '='
            justify-content: space-between;
            font-weight: 400;
            padding: .5em;
            padding-top: .3em;
            padding-bottom: .3em;
            width: $menuitem-width;
        }
        &.active>input+label{
            font-weight: bold;
        }

        &.more>input+label::after{
            content: '=';
        }
        a{
            width:inherit;
            height:inherit;
        }
    }
}



