.topbar{
    box-shadow: 0 0 5px var(--shadow);
}

#menubox{
    *{transition: box-shadow .5s;}

    .menuheader:hover{
        box-shadow: 10px 10px 30px var(--shadow);
    }

    //general dropout menu style (not visible on default)
    @mixin default{
        display: none;
        position: relative;
        //estimated values, probably needs a change
        top: -2.1em;
        left: $menuitem-width;
        //border-left: 1px solid var(--hover);
        //border-right: 1px solid var(--hover);
        --backgroundcolor: var(--background);
        box-shadow: 10px 5px 25px #000000d4;
        &:hover{
            box-shadow: 20px 10px 30px #000000d4;
        }
    }

    //make dropout menu visible
    @mixin visible{
        display: block;
    }

    .menuitem{
        //fixed height -> prevent expand on dropout
        height: 2rem;
        max-width: $menuitem-width;
        min-width: $menuitem-width;
        &:hover{
            --backgroundcolor: var(--hover);
            &.more>input+label::after{
                content: '>';
            }
            &.more >input + label + ul{
                @include visible();
            }
        }
        &.more >input + label + ul{
            @include default();
                      
        }
    }
}