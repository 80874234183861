.alert {
    --backgroundcolor: hsl(var(--color), 60%, 88%);
    --textcolor: hsl(var(--color), 30%, 20%);
    border: 1px solid hsl(var(--color), 20%, 80%);
    padding: 1rem;
    margin: 2rem 0 2rem 0;

    >*{
        &:first-child{
            margin-top: 0 !important;
        }
        &:last-child{
            margin-bottom: 0 !important;
        }
    }
    >h1,
    h2,
    h3,
    h4 {
        margin: 0;
        margin-bottom: 0.8rem;
        font-weight: 500 !important;
    }

    >p {
        margin: 0 !important;
        padding-bottom: 1rem;
        &[lang]:last-child, &[lang]:nth-last-child(2){
            padding-bottom: 0;
        }
        &:last-child:not([lang]){
            padding-bottom: 0;
        }
    }

    >ul {
        padding-left: 1rem;
    }

}