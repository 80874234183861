//topnav
.topnav {
    --textcolor: var(--light);
    >div {
        display: flex;
    }

    a{
        text-decoration: none;
        --textcolor: inherit;
    }

    span {
        padding: $padding-content;
        font-family: monospace;
        font-size: 8pt;
    }

    //container for breadcrumb and language toggler
    #breadandlang{
        justify-content: space-between;
        align-self: end;
        a:hover{
            text-decoration: underline;
        }
        >span span{
            padding: 0;
        }
        // pointer on language change button
        >span+span>label:hover{
            cursor: pointer !important;
        }
    }    

    .socials {
        align-self: center;
        align-items: center;

        a, small{
            display: flex;
        }
        svg, img{
            height: 1.5rem;
            width: auto;
            margin: 0.5rem;
            transition: filter 0.1s;
            pointer-events: auto;
        }

        small{
            font-size: x-small;
            margin-right: 1rem;
        }

        #twitch:hover {
            filter: invert(.5) sepia(1) saturate(5) hue-rotate(200deg);
        }

        #instagram:hover {
            filter: invert(.5) sepia(1) saturate(2.5) hue-rotate(270deg);
        }

        #whatsapp:hover {
            filter: invert(.5) sepia(1) saturate(2.5) hue-rotate(420deg);
        }
    }
    
    .buzzer-state {
        align-self: center;
        align-items: center;

        a, small{
            display: flex;
        }
        small{
            font-size: x-small;
            margin-right: 1rem;
        }
    }

    // main logo
    .logo {
        padding: $padding-content;
        padding-right: 0;
        margin: 0;
        --textcolor: var(--light);
        float: left;
        text-decoration: none;
        align-self: end;

        img {
            width: 2rem;
            height: auto;
            float: left;
            margin-right: $padding-content;
            transition: transform 0.2s ease-in-out;
        }

        &:hover {
            img{
                transform: rotate(-10deg);
            }
        }

        h1 {
            font-size: 1.2rem;
            clear: both;
            line-height: 95%;
            display: inline;
            margin: 0;
            text-transform: uppercase;
        }

        span {
            float: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-family: monospace;
            font-size: 8pt;
            padding: 0;
        }

        .title {
            display: flex;
            flex-direction: column;
        }
    }
}
