footer {
    display: block;
    clear: both;
    height: fit-content;
    padding: 2rem;
    overflow: hidden;

    #footer-links {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        margin: 0 auto;
    }

    .link {
        float: left;
        font-weight: bold;
        padding: 1rem;
        font-size: 1em;
    }

    a {
        --textcolor: var(--light);
    }

    #imprint {
        --textcolor: var(--light);
        width: auto;
    }

    #imprint-img {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        vertical-align: top;
        line-height: 1.5em;
        padding: 1rem;

        img {
            height: 3rem;
            width: auto;
        }
    }

    #imprint-text1 {
        display: block;
        margin-bottom: 1em;
        width: fit-content;
        margin: 0 auto;
        text-align: center;
    }
}