#tableofcontents{
    hyphens: auto;
    font-weight: 400;
    --textcolor: var(--muted);
    ul{
        list-style: none;
        padding-left: 0;
        margin: 0;
        ul{
            padding-left: 0.5rem;
        }
    }
    li{
        padding: 0.2em 0 0.2em 0;
    }
    a{
        --textcolor: inherit;
    }
    text-align:start;
}